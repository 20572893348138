import { IGetTaskIdByMessageUid } from 'CommonTypes/Messages/CheckTaskIdByMessageUid';

import { nodePath } from 'Config/path.js';
import { fetchGet } from 'Services/fetchService.js';

export default async (messageUid: string) => {
    const response: IGetTaskIdByMessageUid = await fetchGet(`${nodePath}/messages/task-id?messageUid=${messageUid}`);

    return response;
};
