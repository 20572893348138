import './assets/scss/menu.original.scss';
import toggler from './components/toggler/toggler.js';
import getMainMenuView from './getMainMenuView.js';
import menuViewModel from './models/menuViewModel.js';
import getHandlerAfterLoad from './utils/handlerAfterLoad.js';
import getHandlerItemClick from './utils/handlerItemClick.js';
import resizeMenu from './utils/resizeMenu.js';

export default ({ router, events }) => {
    const handlerItemClick = getHandlerItemClick(router);
    const handlerAfterLoad = getHandlerAfterLoad(router, events);

    const collapsed = Boolean(localStorage.getItem('mainMenuCollapse'));
    if (collapsed) {
        document.body.classList.add('mini-navbar');
    }

    if (app?.notUser) {
        return false;
    }

    const view = getMainMenuView(handlerItemClick, handlerAfterLoad, collapsed);
    webix.ui(view);
    webix.ui(toggler);
    menuViewModel('main-menu');
    events.on('mainmenu:toggle', resizeMenu, false);
};
