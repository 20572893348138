import { IContactsNameByEmail, IContactsNameByEmailRequest } from 'CommonTypes/Contacts/ContactsForNonUser';
import IContactSimpleSet from 'CommonTypes/Contacts/IContactSimpleSet';

import emptySplitApi from 'Endpoints/emptySplitApi';

export const contactsApi = emptySplitApi
    .injectEndpoints({
        endpoints: build => ({

            getContactsSimpleSet: build.query<IContactSimpleSet[], void>({
                query: () => ({
                    url: 'contacts',
                }),
                providesTags: ['ContactsSimpleSet'],
            }),

            getContactsNameByEmail: build.query<
                IContactsNameByEmail,
                IContactsNameByEmailRequest
            >({
                query: params => ({
                    url: 'contacts/name',
                    params,
                }),
            }),

        }),
    });

export const {
    useGetContactsSimpleSetQuery,
    useGetContactsNameByEmailQuery,
} = contactsApi;
