import { useEffect } from 'react';

import useEnqueueSnackbar from 'Lib/Notistack/useEnqueueSnackbar';
import container from 'Services/container';

interface IProps {
    taskId: number;
}

function TaskExistsMessage({ taskId }: IProps) {
    const { warning } = useEnqueueSnackbar();

    const { events } = container;

    useEffect(() => {
        warning(
            'Задача по этому письму уже существует',
            {
                actionText: `${taskId}`,
                callback: () => events.emit('oldAppInbox:openTask', taskId),
            },
        );
    }, [events, taskId, warning]);

    return (
        null
    );
}

export default TaskExistsMessage;
