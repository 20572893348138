import React from 'react';
import {
    useController,
    UseControllerReturn,
    useFormContext,
    RegisterOptions,
    FieldValues,
} from 'react-hook-form';

import FormHelperText from '@mui/material/FormHelperText';

import { EditorConfig } from '@ckeditor/ckeditor5-core/src/editor/editorconfig';

import Editor from 'Ui/Editor';
import { IClassicEditor } from 'Ui/Editor/types/ICKEditorClassic';

interface IProps {
    name: string;
    editorRef: React.MutableRefObject<IClassicEditor | null>;
    config?: EditorConfig;
    required?: Omit<RegisterOptions<FieldValues>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>
}

function CKEditorRHF(props: IProps) {
    const {
        name,
        editorRef,
        config,
        required,
    } = props;
    const { control } = useFormContext();
    const controller: UseControllerReturn = useController({
        name,
        control,
        rules: required,
    });

    return (
        <>
            { controller.fieldState.error
                ? <FormHelperText error>{controller.fieldState.error?.message}</FormHelperText>
                : undefined}
            <Editor
                onChange={(event, editor) => {
                    const newValue = editor.getData();
                    controller.field.onChange(newValue);
                }}
                value={controller.field.value}
                onReady={(editor) => {
                    // eslint-disable-next-line no-param-reassign
                    editorRef.current = editor;
                }}
                config={config}
            />
        </>
    );
}

export default CKEditorRHF;
