import React, { StrictMode } from 'react';
import { Provider } from 'react-redux';

import { ThemeProvider } from '@mui/material';

import { createRoot, Root } from 'react-dom/client';

import theme from 'Lib/Mui/theme';
import Action from 'Lib/Notistack/DefaultAction';
import StyledSnackbarProvider from 'Lib/Notistack/StyledSnackbarProvider';
import container from 'Services/container';

import ModalWrapper from '../components/ModalWrapper/ModalWrapper';
import TaskExistsMessage from '../components/TaskExistsMessage';
import checkTaskIdByMessageUid from '../services/checkTaskIdByMessageUid';
import IDataFromEvent from '../types/IDataFromEvent';

import createTaskInBackground from './models/createTaskInBackground';

let rootContainer: Root;
let root: HTMLDivElement | undefined | null;

const closeFormAddTas = () => {
    if (rootContainer) rootContainer.unmount();
    if (root) root.remove();
    root = null;
};

const addNewTask = async ({
    type, params, messageId, dataFiles, callback,
}: IDataFromEvent) => {
    closeFormAddTas();

    const { store } = container;

    root = document.createElement('div');
    root.className = 'flex-full-height root-flex-column';
    rootContainer = createRoot(root);
    document.body.append(root);

    if (type === 'taskByInputMail' && messageId) {
        const response = await checkTaskIdByMessageUid(messageId);
        if (response?.id && response?.taskId) {
            rootContainer.render(
                <StrictMode>
                    <ThemeProvider theme={theme}>
                        <StyledSnackbarProvider
                            action={snackbarId => Action(snackbarId)}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            hideIconVariant
                        >
                            <Provider store={store}>
                                <TaskExistsMessage taskId={response.taskId} />
                            </Provider>
                        </StyledSnackbarProvider>
                    </ThemeProvider>
                </StrictMode>,
            );
            return null;
        }
    }

    rootContainer.render(
        <StrictMode>
            <ThemeProvider theme={theme}>
                <StyledSnackbarProvider
                    action={snackbarId => Action(snackbarId)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    hideIconVariant
                >
                    <Provider store={store}>
                        <ModalWrapper
                            type={type}
                            params={params}
                            messageId={messageId}
                            dataFiles={dataFiles}
                            callback={callback}
                        />
                    </Provider>
                </StyledSnackbarProvider>
            </ThemeProvider>
        </StrictMode>,
    );

    return null;
};

export default () => {
    const { events } = container;
    events.on(
        'tasks__formAddTask:addNewTask',
        addNewTask,
        false,
    );
    events.on(
        'tasks__formAddTask:addNewTaskBackground',
        createTaskInBackground,
        false,
    );
    events.on(
        'tasks__formAddTask:closeFormAddTask',
        closeFormAddTas,
        false,
    );
};
