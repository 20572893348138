import React from 'react';
import { useController, UseControllerReturn, useFormContext } from 'react-hook-form';

import {
    Button, FormHelperText, Icon, IconButton,
} from '@mui/material';
import {
    DataGrid, GridRenderCellParams, GridPreProcessEditCellProps,
} from '@mui/x-data-grid';

interface IFile {
    file?: File;
    fileName: string;
    fileDescription: string;
    id: string;
    folder?: string;
    mime?: string;
    sname?: string;
}

interface IProps {
    name: string;
    required?: boolean;
}

function FilesUploader({ name, required }: IProps) {
    const { control } = useFormContext();
    const controller: UseControllerReturn = useController({
        name,
        control,
        rules: {
            required: required
                ? {
                    value: true,
                    message: 'Необходимо прикрепить файлы',
                }
                : undefined,
        },
    });

    const handleEditCommit = (params: GridPreProcessEditCellProps<string>) => {
        const { id } = params;
        const currentProps = params.props;
        const newValue = currentProps.value;
        const [currentFile] = (controller.field.value as IFile[]).filter(file => file.id === id);
        currentFile.fileDescription = newValue ?? '';
        const newFileState = (controller.field.value as IFile[]).map((file) => {
            if (file.id === id) return currentFile;

            return file;
        });

        return controller.field.onChange(newFileState);
    };

    const handleDeleteFile = (params: GridRenderCellParams) => {
        const newStateFiles = (
            controller.field.value as IFile[]
        ).filter(file => file.id !== params.id);
        return controller.field.onChange(newStateFiles);
    };

    const hundleUploadFiles = (event: React.ChangeEvent<HTMLInputElement>) => {
        const fileList = event.target.files;
        const keysFileList = fileList ? Object.keys(fileList) : [];
        const setFiles = keysFileList.reduce((acc, keyFileList) => {
            const file: IFile = {
                file: fileList![(keyFileList as keyof typeof fileList)],
                fileName: fileList![(keyFileList as keyof typeof fileList)].name,
                fileDescription: '',
                id: fileList![(keyFileList as keyof typeof fileList)].name,
            };
            acc.push(file);

            return acc;
        }, [] as IFile[]);

        controller.field.onChange([
            ...controller.field.value,
            ...setFiles,
        ]);
    };

    return (
        <>
            {!!controller.field.value.length
                                && (
                                    <DataGrid
                                        autoHeight
                                        rows={controller.field.value}
                                        columns={[
                                            {
                                                field: 'fileName',
                                                headerName: 'Наименование файла',
                                                sortable: false,
                                                flex: 1,
                                            },
                                            // {
                                            //     field: 'fileDescription',
                                            //     headerName: 'Описание файл',
                                            //     editable: true,
                                            //     sortable: false,
                                            //     flex: 2,
                                            //     preProcessEditCellProps(params) {
                                            //         handleEditCommit(params);

                                            //         return { ...params.props };
                                            //     },
                                            // },
                                            {
                                                field: 'deleteFile',
                                                headerName: '',
                                                renderCell: params => (
                                                    <IconButton aria-label="delete" onClick={() => handleDeleteFile(params)}>
                                                        <Icon><i className="fa fa-trash" /></Icon>
                                                    </IconButton>
                                                ),
                                                align: 'center',
                                                sortable: false,
                                            },
                                        ]}
                                        hideFooter
                                        rowHeight={36}
                                        disableColumnMenu
                                        sx={{
                                            marginBottom: '16px',
                                        }}
                                    />
                                )}
            <Button
                variant="contained"
                component="label"
                startIcon={<Icon><i className="fa fa-upload" /></Icon>}
                id="upload-file"
                sx={{
                    width: '100%',
                    border: controller.fieldState.error ? '1px solid red' : 'none',
                }}
            >
                Загрузить файл
                <input
                    type="file"
                    hidden
                    multiple
                    onChange={hundleUploadFiles}
                />
            </Button>
            {
                controller.fieldState.error
                    ? <FormHelperText error>{controller.fieldState.error?.message}</FormHelperText>
                    : null
            }
        </>
    );
}

export default FilesUploader;
