import constantDefaultProperty from '../../constants/defaultProperty';
import getAllPropertyForForm from '../../services/getAllPropertyForForm';
import { IFiles } from '../../types/IFiles';
import { TTypeTask } from '../../types/TTypeTask';

import getDefaultPropertyForIssue from './getDefaultPropertyForIssue';
import getDefaultPropertyForSubmissionTask from './getDefaultPropertyForSubmissionTask';
import getDefaultPropertyForSubTask from './getDefaultPropertyForSubTask';
import getDefaultPropertyForTask from './getDefaultPropertyForTask';
import getDefaultPropertyForTaskByInputMail from './getDefaultPropertyForTaskByInputMail';
import getDefaultPropertyForTaskMessage from './getDefaultPropertyForTaskMessage';

export default async (
    type: TTypeTask,
    params?: {
        data: { [key: string ]: string | number },
        parentItem?: { id: number, name: string },
    },
    messageId?: string,
    dataFiles?: [] | IFiles[],
) => {
    const data = await getAllPropertyForForm();

    switch (type) {
    case 'task': {
        const defaultProperty = await getDefaultPropertyForTask(data, params);

        return {
            data,
            defaultProperty: {
                ...constantDefaultProperty,
                ...defaultProperty,
            },
        };
    }

    case 'issue': {
        const defaultProperty = getDefaultPropertyForIssue(data, params);

        return {
            data,
            defaultProperty: {
                ...constantDefaultProperty,
                ...defaultProperty,
            },
        };
    }

    case 'taskByInputMail': {
        const defaultProperty = await getDefaultPropertyForTaskByInputMail(data, messageId!);

        return {
            data,
            defaultProperty: {
                ...constantDefaultProperty,
                ...defaultProperty,
            },
        };
    }
    case 'submissionTask': {
        const defaultProperty = await getDefaultPropertyForSubmissionTask(data, params!);

        return {
            data,
            defaultProperty: {
                ...constantDefaultProperty,
                ...defaultProperty,
            },
        };
    }
    case 'subTask': {
        const defaultProperty = await getDefaultPropertyForSubTask(data, params!, dataFiles!);

        return {
            data,
            defaultProperty: {
                ...constantDefaultProperty,
                ...defaultProperty,
            },
        };
    }
    case 'taskMessage': {
        const defaultProperty = await getDefaultPropertyForTaskMessage(data, params!, dataFiles!);

        return {
            data,
            defaultProperty: {
                ...constantDefaultProperty,
                ...defaultProperty,
            },
        };
    }

    default:
        return {
            data,
            defaultProperty: constantDefaultProperty,
        };
    }
};
