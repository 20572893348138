import { Match } from 'navigo';

import { initModule } from 'Services/initModule.js';

export default {
    '/cat/:email/:projectToken': {
        as: 'createATask',
        uses(match: Match) {
            import(
                /* webpackChunkName: "createatask" */ '../../Task/controllers/TaskFormForNonUser'
            ).then(module => initModule(module.default, match));
        },
    },
};
