import React, {
    useMemo, useRef, Dispatch, useCallback,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { FilterOptionsState, Box } from '@mui/material';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { Resizable } from 're-resizable';

import IFunctionWithPath from 'CommonTypes/Functions/IFunctionWithPath';
import TLimitMeasureIds from 'CommonTypes/ITServices/TLimitMeasureIds';
import {
    IProjects, IItServicesWithPath, IUsers, IContacts,
} from 'CommonTypes/Tasks/QuickAddTaskForm/IResponse';

import calculateDeadlineByFunctions from 'Domain/ItServices/LimitMeasure/calculateDeadlineByFunctions';
import calculateDeadlineByLimitMeasure from 'Domain/ItServices/LimitMeasure/calculateDeadlineByLimitMeasure';

import { useGetUserInfoQuery } from 'Endpoints/initApp/initApp.api';

import { IClassicEditor } from 'Ui/Editor/types/ICKEditorClassic';
import { Autocomplete, AutocompleteLongList } from 'Ui/Form/Autocomplete';
import { DatePicker, DateTimePicker } from 'Ui/Form/DatePicker';
import { Editor } from 'Ui/Form/Editor';
import { FilesUploader } from 'Ui/Form/FilesUploader';
import { TextField } from 'Ui/Form/TextField';

import getFilterOptionsForService from 'fsrc/ItServices/models/filterFunctionsByService';
import getFilterOptionsForProject from 'fsrc/ItServices/models/filterServicesByProject';

import classes from '../../assets/style.scss';
import getSubdivision from '../../events/models/getSubdivision';
import { IReducer, IState } from '../../models/reducer';
import { ITaskData } from '../../types/ITaskData';
import TNewValue from '../../types/TNewValue';
import { TTypeTask } from '../../types/TTypeTask';

function GridView({
    type, state, dispatch, disabledFields,
}: {
        type: TTypeTask,
        state: IState,
        dispatch: Dispatch<IReducer>,
        disabledFields: (keyof ITaskData)[],
    }) {
    const { dataForm: data } = state;
    const descriptionRef = useRef<IClassicEditor | null>(null);
    const commentRef = useRef<IClassicEditor | null>(null);

    const form = useFormContext();

    const projectController = useController({ name: 'project', control: form.control });
    const contactController = useController({ name: 'contact', control: form.control });
    const phoneController = useController({ name: 'phone', control: form.control });
    const orgstructureController = useController({ name: 'orgstructure', control: form.control });
    const assigneeController = useController({ name: 'assignee', control: form.control });
    const roleController = useController({ name: 'role', control: form.control });
    const serviceController = useController({ name: 'services', control: form.control });
    const functionsController = useController({ name: 'functions', control: form.control });
    const deadlineController = useController({ name: 'deadline', control: form.control });
    const taskTypeController = useController({ name: 'taskTypes', control: form.control });

    const onChangeProjectByContact = useMemo(() => (newValue: IContacts) => {
        contactController.field.onChange(newValue);
        const phone = newValue?.phone || '';
        phoneController.field.onChange(phone);
        const subdivision = getSubdivision(data.orgstructure, newValue?.subdivisionId || 0);
        orgstructureController.field.onChange(subdivision);

        const projectTmp = form.getValues('project');
        if (!projectTmp) {
            const currentProject = newValue.projectId
                ? data.projects.find(project => project.id === newValue.projectId)
                : null;

            projectController.field.onChange(currentProject);
        }
    }, [contactController.field, projectController.field, data.projects]);

    const setDeadline = async (limitation: number, limitMeasure: TLimitMeasureIds) => {
        const newDeadline = await calculateDeadlineByLimitMeasure(
            limitation,
            limitMeasure,
        );

        deadlineController.field.onChange(newDeadline);
    };

    const onChangeService = useMemo(() => (newValue: IItServicesWithPath) => {
        if (newValue.limitation > 0 && newValue.limit_measure !== null) {
            setDeadline(newValue.limitation, newValue.limit_measure);
        }

        serviceController.field.onChange(newValue);
        if (newValue?.roleId) {
            roleController.field.onChange(
                data.roles.find(element => element.id === newValue.roleId),
            );
            assigneeController.field.onChange(null);
        }
    }, [serviceController.field, roleController.field, assigneeController.field, data.roles]);

    const onChangeUsersAndRolesByProject = useMemo(() => (newValue: IProjects) => {
        dispatch({
            type: 'updateRuqired',
            payload: {
                subdivisionIsRequired: newValue.subdivisionIsRequired,
                itserviceIsRequired: newValue.itserviceIsRequired,
                functionsIsRequired: newValue.functionsIsRequired,
            },
        });
        projectController.field.onChange(newValue);
        const { assignee } = form.formState.defaultValues!;

        assigneeController.field.onChange(assignee);
        roleController.field.onChange(null);

        const serviceCurrent = serviceController.field.value;
        const servicesIdsByProject = (newValue.services || '').split(',').filter(el => el).map(el => +el);
        if (serviceCurrent && !servicesIdsByProject.includes(serviceCurrent.id)) {
            serviceController.field.onChange(null);
        }

        if (servicesIdsByProject.length === 1) {
            const serviceObj = data.services.find(value => value.id === servicesIdsByProject[0]);
            onChangeService(serviceObj!);
        }

        if (newValue?.taskTypeId && !disabledFields.includes('taskTypes')) {
            const taskTypeEntity = data.taskTypes.find(
                taskType => taskType.id === +(newValue?.taskTypeId || 0),
            );
            if (taskTypeEntity) {
                taskTypeController.field.onChange(taskTypeEntity);
            }
        }
    }, [
        dispatch,
        projectController.field,
        form.formState.defaultValues,
        assigneeController.field,
        roleController.field,
        serviceController.field,
        disabledFields,
        data.services,
        data.taskTypes,
        onChangeService,
        taskTypeController.field,
    ]);

    const onChangeUsersByRoles = useMemo(() => (newValue: TNewValue) => {
        roleController.field.onChange(newValue);
        assigneeController.field.onChange(null);
    }, [roleController.field, assigneeController.field]);

    const onChangeFunction = useMemo(() => async (newValue: IFunctionWithPath[]) => {
        const currentService = serviceController.field.value;
        if (currentService && currentService.functionsLimitation) {
            const maxDate = await calculateDeadlineByFunctions(
                currentService.functionsLimitation,
                deadlineController.field.value,
                newValue,
            );
            deadlineController.field.onChange(maxDate);
        }
        functionsController.field.onChange(newValue);
    }, [functionsController.field]);

    const onChangeRolesByUsers = useMemo(() => (newValue: TNewValue) => {
        assigneeController.field.onChange(newValue);
        roleController.field.onChange(null);
    }, [roleController.field, assigneeController.field]);

    const onChangeContactsByDefault = useMemo(() => () => {
        const { contact } = form.formState.defaultValues!;
        contactController.field.onChange(contact);
    }, [contactController.field, form.formState.defaultValues]);

    const onChangeUsersByDefault = useMemo(() => () => {
        const { assignee } = form.formState.defaultValues!;
        assigneeController.field.onChange(assignee);
        roleController.field.onChange(null);
    }, [assigneeController.field, form.formState.defaultValues, roleController.field]);

    const filterUsers = (option: IUsers[], stateFilter: FilterOptionsState<IUsers>) => {
        const { inputValue } = stateFilter;
        const { value } = projectController.field;
        const userFilterOptions = value
            ? option.filter(user => (
                user.projectsId.includes(`${value.id}`) && user.value.toLowerCase().includes(inputValue.toLowerCase())
            ))
            : [];

        return userFilterOptions;
    };

    const filterFunctions = useCallback(
        getFilterOptionsForService(serviceController),
        [serviceController],
    );

    const filterItServices = useCallback(
        getFilterOptionsForProject(projectController.field?.value),
        [projectController.field?.value],
    );

    const { data: userInfo } = useGetUserInfoQuery();

    return (
        <form className={classes.form}>
            <Box sx={{ width: 1 }}>
                <Box className={classes.form_content}>
                    <Resizable
                        className={classes.form_resizeble}
                        defaultSize={{
                            width: `${state.widthLeftSide || 75}%`,
                            height: 'inherit%',
                        }}
                        minHeight="inherit"
                        maxWidth="100%"
                        minWidth="275"
                        onResizeStop={(e, direction, ref) => {
                            const { width } = ref.style;
                            dispatch({
                                type: 'changeWidthLeftSide',
                                payload: parseFloat(width),
                            });
                        }}
                    >
                        <Box sx={{ flexGrow: 1 }} className={classes['form_left-side']}>
                            <Grid container>
                                <Grid item xs={3} sx={{ padding: '0 5px 0 0' }}>
                                    <Autocomplete
                                        name="doctype"
                                        label="Тип вх. документа"
                                        options={data.docTypes}
                                        blurOnSelect
                                    />
                                </Grid>
                                <Grid item xs={3} sx={{ padding: '0 5px' }}>
                                    <TextField
                                        name="innumber"
                                        label="Номер вх. документа"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3} sx={{ padding: '0 5px' }}>
                                    <DatePicker
                                        name="indate"
                                        label="Дата вх. документа"
                                    />
                                </Grid>
                                <Grid item xs={3} sx={{ padding: '0 0 0 5px' }}>
                                    <TextField
                                        name="intotal"
                                        label="Сумма вх. документа"
                                        fullWidth
                                    />
                                </Grid>
                            </Grid>
                            <TextField
                                name="taskName"
                                label="Наименование задачи"
                                fullWidth
                                required={{
                                    required: {
                                        value: true,
                                        message: 'Поле не может быть пустым',
                                    },
                                    maxLength: {
                                        value: 512,
                                        message: 'Превышено максимальное количество символов',
                                    },
                                }}
                                autoFocus
                            />
                            <Editor
                                name="desc"
                                editorRef={descriptionRef}
                                config={{ placeholder: 'Описание' }}
                            />
                            <TextField
                                name="criteria"
                                multiline
                                fullWidth
                                rows={5}
                                label="Критерии приёмки"
                            />
                            <Editor
                                name="comment"
                                editorRef={commentRef}
                                config={{ placeholder: 'Комментарии' }}
                            />
                            <FilesUploader name="filesUploader" />
                        </Box>
                    </Resizable>
                    <Box className={classes['form_right-side']}>
                        <Box className={classes['form_right-side-wrapper']}>
                            { type !== 'issue'
                                && (
                                    <Autocomplete
                                        name="taskTypes"
                                        label="Бизнес процесс"
                                        options={data.taskTypes}
                                        disableClearable
                                        blurOnSelect
                                        disabled={disabledFields.includes('taskTypes')}
                                    />
                                )}
                            <Autocomplete
                                name="serviceClasse"
                                label="Класс обслуживания"
                                options={data.serviceClasses}
                                disableClearable
                                blurOnSelect
                            />
                            <AutocompleteLongList<IContacts>
                                name="contact"
                                label="Контакт"
                                options={data.contacts}
                                callback={onChangeProjectByContact}
                                disableClearable
                                required
                                avatar
                                blurOnSelect
                                disabled={!!userInfo?.guest}
                                extraButton={(
                                    <Tooltip title="Выбрать себя Контактом">
                                        <IconButton onClick={onChangeContactsByDefault}>
                                            <i className="fa fa-user" />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            />
                            <TextField
                                name="phone"
                                fullWidth
                                label="Телефон контакта"
                            />
                            <Autocomplete<IItServicesWithPath>
                                name="orgstructure"
                                label="Подразделение"
                                options={data.orgstructure}
                                blurOnSelect
                                listValue="path"
                                inputValue="path"
                                required={!!state.dataForm.subdivisionIsRequired}
                            />
                            { type !== 'issue'
                                && (
                                    <Autocomplete<IProjects>
                                        name="project"
                                        label="Проект"
                                        options={data.projects}
                                        callback={onChangeUsersAndRolesByProject}
                                        disableClearable
                                        required
                                        blurOnSelect
                                    />
                                )}
                            { type !== 'issue'
                                && (
                                    <Autocomplete<IItServicesWithPath>
                                        name="services"
                                        label="Сервис"
                                        options={data.services}
                                        filterOptions={filterItServices}
                                        blurOnSelect
                                        callback={onChangeService}
                                        listValue="path"
                                        inputValue="path"
                                        required={!!state.dataForm.itserviceIsRequired}
                                    />
                                )}
                            { type !== 'issue'
                                && (
                                    <Autocomplete
                                        name="role"
                                        label="Роль"
                                        options={data.roles}
                                        avatar
                                        blurOnSelect
                                        callback={onChangeUsersByRoles}
                                    />
                                )}
                            { type !== 'issue'
                                && (
                                    <Autocomplete
                                        name="assignee"
                                        label="Исполнитель"
                                        options={data.users}
                                        filterOptions={filterUsers}
                                        avatar
                                        blurOnSelect
                                        callback={onChangeRolesByUsers}
                                        extraButton={(
                                            <Tooltip title="Выбрать себя Исполнителем">
                                                <IconButton onClick={onChangeUsersByDefault}>
                                                    <i className="fa fa-user" />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    />
                                )}
                            <DateTimePicker
                                name="deadline"
                                label="Срок"
                            />
                            { userInfo?.guest ? null
                                : (
                                    <AutocompleteLongList
                                        name="observers"
                                        label="Наблюдатели"
                                        multiple
                                        options={data.contacts}
                                        blurOnSelect
                                    />
                                )}
                            { type !== 'issue'
                                && (
                                    <Autocomplete<IFunctionWithPath[]>
                                        name="functions"
                                        label="Функции"
                                        multiple
                                        options={data.functions}
                                        filterOptions={filterFunctions}
                                        blurOnSelect
                                        callback={onChangeFunction}
                                        listValue="path"
                                        required={!!state.dataForm.functionsIsRequired}
                                    />
                                )}
                            <AutocompleteLongList
                                name="parentTaskId"
                                label="Ведущая задача"
                                options={data.tasks}
                                blurOnSelect
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </form>
    );
}

export default GridView;
